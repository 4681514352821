import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {faPhone, faEnvelope as faEmail} from "@fortawesome/free-solid-svg-icons"
// import {faWhatsapp} from "@fortawesome/free-brands-svg-icons"

/**
 * @title Contatti Rapidi Component
 * @param {Boolean} darkActive 
 * @returns {React.Component} Div HTML Element
 * @description Componente che renderizzato mostra i contatti rapidi dell'azienda. 
 */
const FlashContact = ({darkActive}) =>{
    
    const link_style = `relative hover:text-primary-500 dark:hover:text-primary-500
    after:duration-700 after:content-[''] after:absolute after:w-full after:h-0.5
    after:top-5 after:bg-primary-500 after:left-0 after:scale-0
    after:hover:scale-100 after:origin-bottom-left`

    return(
        <div className="flex landscape:flex-col landscape:md:flex-row portrait:flex-row items-center justify-between text-center mt-4 w-full">
            <div/>
            <a href={`${process.env.GATSBY_TELEPHONE_CONTACT_URL}`} target="_blank" rel="noreferrer"
            className={link_style + " text-primary-500 font-semibold " + (darkActive && " dark:text-primary-400 dark:hover:text-primary-500")}>
                <FontAwesomeIcon icon={faPhone} /> Telefono
            </a>
            {/* <a  href={`${process.env.GATSBY_WHATSAPP_CONTACT_URL}`} target="_blank" rel="noreferrer"
            className={link_style + " text-emerald-500 font-semibold " + (darkActive && "dark:text-emerald-200 dark:hover:text-primary-500")}>
                <FontAwesomeIcon icon={faWhatsapp} /> WhatsApp
            </a> */}
            {/* <span role="button" tabIndex={0}
            className={link_style + " text-red-500 dark:text-red-200 dark:hover:text-primary-500 font-semibold cursor-pointer"}
            onClick={()=>{
            document.getElementById('Contattaci!').scrollIntoView({behavior: "smooth"})
            }}
            onKeyDown={()=>{
            document.getElementById('Contattaci!').scrollIntoView({behavior: "smooth"})
            }}>
            <FontAwesomeIcon icon={faEmail} /> Email
            </span> */}
            <a  href={`${process.env.GATSBY_EMAIL_CONTACT_URL}`} target="_blank" rel="noreferrer"
            className={link_style + " text-red-500 font-semibold " + (darkActive && "dark:text-red-200 dark:hover:text-primary-500")}>
                <FontAwesomeIcon icon={faEmail} /> Email
            </a>
            <div/>
        </div>
    )
}

export default FlashContact