/**
 * @title Report Rendicontazione Data
 * @exports Object
 * @description File che ritorna un oggetto JS con i dati della rendicontazione dei
 * contributi ricevuti nell'anno 2020/2021.
 */

const Data = 
{
    title: "RENDICONTAZIONE DEI CONTRIBUTI PUBBLICI RICEVUTI NELL’ANNO 2020/2021",
    subTitle: "(Ex Legge 124 del 2017)",
    docDate: "Tavagnacco 30 giugno 2022",
    table: {
        header: "TECHFRIULI SRL – UNIPERSONALE C.F. 02602970309",
        columns:
        [
            {
                id:0,
                title:"DATA DI INCASSO",
            },
            {
                id:1,
                title:"SOGGETTO EROGATORE",
            },
            {
                id:2,
                title:"CAUSALE",
            },
            {
                id:3,
                title:"SOMMA INCASSATA",
            },
        ],
        content:
        [
            {
                0:"03/06/2020",
                1:"Stato Decreto Liquidità(DL 8 aprile 2020 n. 23)",
                2:"Finanziamento",
                3:"25.000,00 €",
            },
            {
                0:"16/07/2020",
                1:"STATO del decreto Rilancio (D.L. n. 34/2020)",
                2:"Credito imposta affitti",
                3:" 908,24 €",
            },
        ],
        totalAmount: "25.908,24 €",
    },    
}

export default Data