import React from "react"

import costanti from "../../properties/costanti"

/**
 * @title Socials Component
 * @param {String} classes 
 * @returns {React.Component} Div HTML Element
 * @description Componente che ritorna un HTML Div con all'interno i social del sito importati.
 */

const Socials = ({classes}) =>{
    return(
        <div className={"flex " + classes}>
        {costanti.social_list.map((social, index) => {
          return(
            <a href={social.link} key={index} target="_blank" rel="noreferrer">
              <div className="text-3xl p-5 hover:text-primary-500 hover:scale-125 transition duration-300">
                {social.icon}
              </div>
            </a>
          )
        })}
      </div>
    )
}

export default Socials