import { useEffect, useRef } from 'react'

/**
 * @title useTimeout Custom Hook
 * @param {Function} callback 
 * @param {Number} delay
 * @description Hook che permette di gesitre la funzione di chiusura del Toast Component corrispondente
 * mantenendo il riferimento corretto ed eseguendo "callback" dopo il delay impostato.
 */

export const useTimeout = (callback, delay) => {
  const savedCallback = useRef(callback)

  // Remember the latest callback if it changes.=
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the timeout
  useEffect(() => {
    // Don't schedule if no delay is specified
    if (delay === null) return

    const id = setTimeout(() => savedCallback.current(), delay)

    return () => clearTimeout(id)
  }, [delay])
}