import React, { useState, useEffect } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClose} from '@fortawesome/free-solid-svg-icons'

/**
 * @title Manifesto Modal Component
 * @param {Function} handleClose
 * @param {*} children
 * @returns {React.Component} Div Html element
 * @description Componente con la definizione della Modal dentro cui visualizzare
 * il report della rendicontazione e i contributi UE. L'animazione è gestita dallo
 * useEffect() e dalle classi CSS di Tailwind impostate tramite uno state.
 */
const Modal = ({ handleClose, children }) =>{

    const [animationIn, setAnimationIn] = useState("opacity-0")

    useEffect(()=>{
        setAnimationIn(" opacity-100 duration-1000")
    },[])
    
    return(
        <div className={"fixed w-full h-full left-0 top-0 z-20 bg-black/60 flex items-center " + animationIn}>
            <div className="bg-white m-auto w-11/12 h-4/5 md:w-4/5 md:h-4/5 rounded-3xl ">
                <div className="modal-container relative w-full h-full overflow-y-scroll overflow-x-hidden">
                    <button
                    className="fixed top-[8%] left-[85%] md:left-[88%] border-2 border-red-400
                    font-bold bg-red-600 text-white px-4 py-2 rounded-xl hover:bg-red-400 duration-300 "
                    type="button" onClick={handleClose}>
                        <FontAwesomeIcon icon={faClose} />
                    </button>
                    <div className="m-3 md:m-7 lg:m-16">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal