import React, { useState, useEffect } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBarsStaggered as faSettings, faClose} from '@fortawesome/free-solid-svg-icons'
import FloatMenu from "../navbar/float-menu"
import costanti from "../../../properties/costanti"

/**
 * @title Settings Component
 * @param No Nessun Parametro
 * @returns {React.Component} Span Html element
 * @description Componente che importa ed usa il "Float Menu". Renderizzato ritorna uno span con all'interno
 * il button per aprire il menù e, a menù aperto, un livello grigio opaco come sfondo con il menù richiamato.
 * Le animazioni in entrata e in uscita sono gesite tramite classi TailwindCSS e hook React.
 */

const SettingsButton = () => {

  const [open,setOpen] = useState(false)
  const [padding, setPadding] = useState("py-2 px-3")
  const [animationIn,setAnimationIn] = useState("scale-0 rounded-l-full rounded-br-full")
  const [opacity,setOpacity] =useState("opacity-0")

  const handleCloseMenu = () =>{
    setOpen(false)
  }

  useEffect(()=>{
    if(open){
      setPadding("py-2 px-4")
      setAnimationIn("scale-100 ")
      // rounded-br-3xl rounded-l-3xl
    }else{
      setPadding("py-2 px-3")
      setAnimationIn("scale-0 rounded-l-full rounded-br-full")
    }
  },[open])
  
  useEffect(()=>{
    if(open){
      setOpacity("opacity-100 duration-500 delay-500")
    }else{
      setOpacity("opacity-0")
    }
  },[animationIn,open])

  return(
    <span className="hidden md:block">
      <div data-sal="zoom-in" data-sal-duration="900" 
      className='z-40 fixed landscape:top-10 landscape:md:top-9 portrait:top-9 right-5'>
        <button
        className={`text-gray-200 dark:text-white rounded-lg text-xl transition duration-300
        hover:shadow-lg border `+ padding +
        (open 
        ? 
        " opacity-100 bg-red-500 border-white"
        :
        " opacity-50 hover:opacity-100 bg-secondary-600 dark:bg-secondary-500 border-secondary-500")}
        onClick={()=>{
          let openState = open
          setOpen(!openState)
        }}>
          <FontAwesomeIcon icon={open ? faClose : faSettings } />
        </button>
      </div>

      <div className={"fixed w-full h-full left-0 top-0 z-30 bg-black/60 " + animationIn}
      onClick={handleCloseMenu} role="button" tabIndex={0} onKeyDown={handleCloseMenu} >
      </div>

      <div
      className={"fixed flex flex-col top-0 " + costanti.theme.layout.width +
      "bg-white dark:bg-secondary-600 lg:justify-center z-40 shadow-2xl " +
      "items-center origin-top-right duration-500 border-primary-500 border-0 " +
      "border-0 modal-container overflow-auto h-full " + animationIn }>
        <span className={"w-full p-10 duration-100 " + opacity }>
          <FloatMenu handleCloseMenu={handleCloseMenu} showLogo showLink />
        </span>
      </div>
    </span>
  )
}

export default SettingsButton