import React, { useEffect, useState } from "react"


/**
 * @title Manifesto Page Component
 * @param {Object} manifesto
 * @returns {React.Component} Div Html element
 * @description Componente con il layout della pagina con i dettagli del bando/manifesto. L'animazione in apertura
 * viene gestita dall'Hook react useEffect() e dalle classi TailwindCSS. Il parametro in input è l'oggetto "manifesto"
 * recuperato dal file "data.js".
 */

const Page = ({manifesto}) => {
  
  const [animationIn, setAnimationIn] = useState("opacity-0")
  const bando = manifesto
  const title_style = "text-xl sm:text-2xl md:text-4xl xl:text-5xl my-10 text-primary-500 font-bold uppercase"

  useEffect(()=>{
    setAnimationIn(" opacity-100 duration-1000")
  },[])

  return(

      Boolean(!bando)
      
      ?
      
      <div className="text-secondary-600 max-w-full text-center md:text-justify">
        <p>Nessun bando trovato</p>
      </div>

      :
      
      <div className={"modal-container text-secondary-600 max-w-full text-center md:text-justify " + animationIn}>
        <p className={title_style}> {bando.title}</p>
        
        <img src={bando.img} alt="POR_FESR" className="py-5 max-w-full md:max-w-lg lg:max-w-xl xl:max-w-2xl"/>
        
        <p className="font-bold my-10">
          {bando.short_description}
        </p>

        <p className={title_style}>Descrizione</p>
        
        {bando.description}

        <p className={title_style}>Contributo concesso</p>
        
        {bando.contribution + " €"}
        
        <p className={title_style}>Obiettivi</p>
        
        <ul className="list-disc list-inside text-justify">
        {
          bando.goal.map(
            (goal, index) =>{
              return(
                <li key={index}>{goal}</li>
              )
            }
          )
        }
        </ul>

        <p className={title_style}>Risultati</p>

        <ul className="list-disc list-inside text-justify">
        {
          bando.result.map(
            (res, index) =>{
              return(
                <li key={index}>{res}</li>
              )
            }
          )
        }
        </ul>

        {
        bando.extra && 
          <p className="py-5 text-justify">
            {bando.extra}
          </p>
        }

        <img src={'/images/manifesto/POR_FESR.png'} alt="POR_FESR" className="my-10 max-w-full"/>
      </div>
  )
}

export default Page