import { useLayoutEffect } from "react"

/**
 * @title ScrollSpy
 * @param {Function} handleScroll
 * @param {String} selector
 * @description Componente che crea degli intersection Observer a partire dal selector, utilizzato per
 * recuperare tutti gli elementi del DOM su cui utilizzare l'API. Per maggiori dettagli
 * sull'API IntersectionObserver consultare la documentazione ufficiale.
 * Il parametro handleScroll viene utilizzato riceve una funzione da eseguire quando si attiva l'Observer.
 */

export const ScrollSpy = ({ handleScroll, selector }) => {

    const isInViewPort = (entry, offset = 300) => {
        const rect = entry.boundingClientRect
        // console.log("top: " + rect.top)
        // console.log("bottom: " +rect.bottom)
        // console.log(0 + offset)
        return rect.top <= 0 + offset && rect.bottom >= 0
    }

    useLayoutEffect(() => {
        // const scrollables = document.querySelectorAll("[data-scrollspy]")
        const scrollables = document.querySelectorAll(selector)
        for (let scrollable of scrollables) {
            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        handleScroll && handleScroll(entry, isInViewPort(entry))
                    })
                },
                {
                    root: null,
                    rootMargin: "0px",
                    threshold: [0.0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0]
                    // threshold: [0, 0.25, 0.5, 0.75, 1]
                }
            )
            observer.observe(scrollable)
        }
    }, [handleScroll, selector])

    return null
}
