import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTruck,faHandshake,faUserFriends } from '@fortawesome/free-solid-svg-icons'
import { faFacebookSquare, faLinkedin } from '@fortawesome/free-brands-svg-icons'
// import Svg from "../components/ui/svg"


/**
 * @title Costanti
 * @returns {Object} Esporta un Object con vari oggetti con i dati statici del sito.
 * @description Componente container con tutti gli oggetti / properties utilizzate all'interno del sito. 
 * Oltre agli elementi delle pagine, è presente anche un oggetto "theme" che racchiude una prima bozza del tema
 * inteso come insieme di classi tailwind a seconda del contesto o del componente. In futuro si potrebbe spostare
 * nel file di configurazione di Tailwind stesso
 */
const costanti = {

  features_list :
  [
    {
      title: "Rapidità",
      description_short: "Affianchiamo la visita a domicilio all'assistenza da remoto al fine di contenere i tempi e i costi dell'intervento",
      description: "Progettiamo e realizziamo soluzioni software per ogni specifica esigenza con l’obiettivo di ottenere la piena soddisfazione del cliente; siamo specializzati nello sviluppo di applicazioni web e di integrazioni a sistemi informativi aziendali, volte alla semplificazione dei processi lavorativi. Collaboriamo con alcuni partner tecnologici per la realizzazione di applicazioni utilizzate dalla pubblica amministrazione e rivolte ai cittadini. Siamo fortemente orientati all’utilizzo di prodotti opensource e la nostra competenza su Java e JEE è il frutto di anni di esperienza maturata nella realizzazione di prodotti e soluzioni. Possediamo specifiche competenze sullo sviluppo di prodotti web dinamici e sulla gestione e personalizzazione dei principali sistemi CMS opensource.",
      icon: <FontAwesomeIcon icon={faTruck} />,
    },
    {
      title: "Affidabilità",
      description_short: "Proposte studiate per le tue esigenze attuali e future",
      description: "Progettiamo e realizziamo soluzioni software per ogni specifica esigenza con l’obiettivo di ottenere la piena soddisfazione del cliente; siamo specializzati nello sviluppo di applicazioni web e di integrazioni a sistemi informativi aziendali, volte alla semplificazione dei processi lavorativi. Collaboriamo con alcuni partner tecnologici per la realizzazione di applicazioni utilizzate dalla pubblica amministrazione e rivolte ai cittadini. Siamo fortemente orientati all’utilizzo di prodotti opensource e la nostra competenza su Java e JEE è il frutto di anni di esperienza maturata nella realizzazione di prodotti e soluzioni. Possediamo specifiche competenze sullo sviluppo di prodotti web dinamici e sulla gestione e personalizzazione dei principali sistemi CMS opensource.",
      icon: <FontAwesomeIcon icon={faHandshake} />,
    },
    {
      title: "Professionalità",
      description_short: "Un team di esperti vi guiderà verso soluzioni mirate e innovative",
      description: "Progettiamo e realizziamo soluzioni software per ogni specifica esigenza con l’obiettivo di ottenere la piena soddisfazione del cliente; siamo specializzati nello sviluppo di applicazioni web e di integrazioni a sistemi informativi aziendali, volte alla semplificazione dei processi lavorativi. Collaboriamo con alcuni partner tecnologici per la realizzazione di applicazioni utilizzate dalla pubblica amministrazione e rivolte ai cittadini. Siamo fortemente orientati all’utilizzo di prodotti opensource e la nostra competenza su Java e JEE è il frutto di anni di esperienza maturata nella realizzazione di prodotti e soluzioni. Possediamo specifiche competenze sullo sviluppo di prodotti web dinamici e sulla gestione e personalizzazione dei principali sistemi CMS opensource.",
      icon: <FontAwesomeIcon icon={faUserFriends} />,
    },
  ],

  // services_list :
  // [
  //   {
  //     title: "Assistenza e Supporto informatico",
  //     description: "Garantiamo un attento e pronto servizio di assistenza all’utente (#bhelp desk#b) da #bremoto#b e non solo, fornendo un adeguato sostegno finalizzato ad un utilizzo ottimale degli strumenti informatici aziendali. Il nostro team offre un supporto completo a 360° e fornisce #bsoluzioni rapide ed efficaci#b per la risoluzione di qualsiasi problema tecnico di natura hardware e software e per mantenere aggiornata e protetta la rete informatica.",
  //     img: "/images/contact-2.jpg",
  //     svg: <Svg.Information />,
  //   },
  //   {
  //     title: "Assistenza Sistemistica",
  //     description: "Forniamo una consulenza a #b360°#b che va dalla progettazione alla realizzazione e il collaudo dell’infrastruttura informatica e prosegue con l’evoluzione del sistema del cliente passo dopo passo. Forniamo server fisici e virtualizzati, client e dispositivi di rete. Gestiamo politiche di controllo sugli accessi e verifiche sulla #bsicurezza informatica#b.",
  //     img: "/images/contact-2.jpg",
  //     svg: <Svg.Hello />,
  //   },
  //   {
  //     title: "Sviluppo Web",
  //     description: "Realizziamo o personalizziamo Siti Web, Form di ricerca e/o inserimento dati, Landing Page e integrazioni con WordPress. Per i siti web forniamo servizi di monitoraggio, ottimizzazione SEO, configurazione di Google Analytics, Cookie e Privacy Solution in linea con il GDPR e integrazione con altri software. Offriamo quindi #bsoluzioni su misura#b, garantendo un risultato allineato alle aspettative e agli obiettivi del cliente.",
  //     img: "/images/home-3.jpg",
  //     svg: <Svg.Web_Builder />,
  //   },
  //   {
  //     title: "Sviluppo Software",
  //     description: "Il nostro team di full-stack developer si occupa di progettazione e sviluppo di Software SaaS (as a Service – Software come servizio in abbonamento) e cloud based. Realizziamo #bapplicazioni#b web #bpersonalizzabili#b e backend con la tecnologia che più si adatta all’esigenza, garantendo quindi prodotti e #bsistemi aggiornati, sicuri e affidabili.#b",
  //     img: "/images/home-3.jpg",
  //     svg: <Svg.Software_Engineer />,
  //   },
  //   {
  //     title: "Sviluppo App Mobile",
  //     description: "Sviluppiamo app per le principali piattaforme mobile, ovvero #biOS#b e #bAndroid#b. Le applicazioni custom verranno realizzate in linea con le policy dei sistemi operativi, garantendone l’uso e la corretta visualizzazione su ogni tipologia di device.",
  //     img: "/images/home-3.jpg",
  //     svg: <Svg.Web_Mobile />,
  //   },
  //   {
  //     title: "Code Refactoring & Analisi del Progetto",
  //     description: "Valutiamo applicazioni o prodotti già esistenti per #brinnovarne#b il codice e garantire performance migliori e/o per poterlo poi #bpersonalizzare#b in base alle necessità del cliente. Analizziamo l’idea o la necessità del cliente e proponiamo l’architettura software più adatta.",
  //     img: "/images/home-3.jpg",
  //     svg: <Svg.Pair_Programming />,
  //   },
  // ],
  
  social_list :
  [
    {
      title: "Facebook",
      link: process.env.GATSBY_FACEBOOK_CONTACT_URL,
      icon: <FontAwesomeIcon icon={faFacebookSquare} />,
    },
    {
      title: "Linkedin",
      link: process.env.GATSBY_LINKEDIN_CONTACT_URL,
      icon: <FontAwesomeIcon icon={faLinkedin} />,
    },
    // {
    //   title: "Whatsapp",
    //   link: process.env.GATSBY_WHATSAPP_CONTACT_URL,
    //   icon: <FontAwesomeIcon icon={faWhatsappSquare} />,
    // },
  ],

  partners :  [
    {
      title: 'Acronis',
      link: 'https://www.acronis.com/it-it/',
      img: './images/partner/acronis.png',
    },
    // {
    //   title: 'Iubenda',
    //   link: 'https://www.iubenda.com/it/',
    //   img: '/partner/iubenda.svg',
    // },
    {
      title: 'Promelit',
      link: 'https://promelit.it/',
      img: './images/partner/promelit.png',
    },
    {
      title: 'TechData',
      link: 'https://it.techdata.com/',
      img: './images/partner/techdata.png',
    },
    {
      title: 'Dell',
      link: 'https://www.dell.com/it-it',
      img: './images/partner/dell.svg',
    },
    {
      title: 'HP',
      link: 'https://www.hp.com/',
      img: './images/partner/hp.png',
    },
    {
      title: 'TrendMicro',
      link: 'https://www.trendmicro.com/it_it/business.html',
      img: './images/partner/trendmicro.png',
    }
  ],

  policy : {
    tag : {
      privacy : '_PRIVACY_',
      cookies : '_COOKIES_',
    }
  },

  theme:{
    navbar:{
      height:{
        fromLZise: "10vh",
        fromMSize: "8vh",
        defaultInt:10, //vh
        default: "6vh",
      },
      bgColor: "white",
      zIndex: " z-50 ",
    },
    color:{
      hex: "#219edd",
      background:{
        light: " bg-white ",
        dark: " dark:bg-secondary-600 ",
        both: " bg-white dark:bg-secondary-600 ",
        both_inverse: " dark:bg-white bg-secondary-600 ",
        fill: " fill-white dark:fill-secondary-600  ",
        svg: " fill-white dark:fill-secondary-600 ",
        
      },
      img:{
        class: " bg-[url('/images/home-bg.png')] bg-cover ",
        inverted: " bg-[url('/images/home-bg-hover.png')] bg-cover ",
      },
      footer: {
        element: " bg-secondary-600 dark:bg-white duration-300 ",
        text: " text-white dark:text-secondary-600 ",
      }
    },
    link : {
      style: "  ",
    },
    layout : {
      width: " w-full md:w-9/12 lg:w-10/12 xl:w-9/12 mx-auto ",
    },
  }
}

export default costanti