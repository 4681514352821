import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import costanti from "../../properties/costanti"

/**
 * @title Logo Component
 * @param {String} classes
 * @param {Function} handleOnClick
 * @returns {React.Component} 
 * @description Componente che ritorna un immagine Gatsby del logo aziendale recuperato dalle risorse tramite query GraphQL.
 * La query ottimizza il recupero della risorsa e la ritorna con il formato indicato. Il componente prevede anche la gestione
 * di un eventuale funzione da eseguire prima di indirizzare all'homepage.
 */

// import Img from "gatsby-image"
const query = graphql`
  query getImagesByName {
    image: file(relativePath: {eq: "techfriuli-logo.png"}) {
      childImageSharp {
        gatsbyImageData(
          width:150
          quality: 90
          layout: CONSTRAINED
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

const Logo = ({classes, handleOnClick}) => {
  return(
    <AniLink
    paintDrip to="/"
    hex={costanti.theme.color.hex}
    onClick={()=>{handleOnClick && handleOnClick()}}>
      <GatsbyImage 
      image={getImage(useStaticQuery(query)?.image.childImageSharp.gatsbyImageData)}
      className={classes}
      loading="eager"
      alt="Techfriuli" />
    </AniLink>
  )
}

export default Logo