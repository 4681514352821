import React, { useState } from "react"

import Logo from "./ui/logo"
import Modal from "./manifesto/container"
import ManifestoPage from "./manifesto/layouts/manifesto"
import ManifestoData from "./manifesto/data"
import ReportingPage from "./manifesto/layouts/reporting"
import ReportingData from "./manifesto/reporting/anno-2020-2021"
import Socials from "./common/socials"
import FlashContact from "./common/flash-contact"


/**
 * @title Footer Component
 * @param No Nessun parametro
 * @returns {React.Component} Footer Component dentro un Div Html
 * @description Footer caricato nel layout di tutte le pagine del sito. Presente in version light e dark.
 * La struttura prevede una suddivisione in 3 colonne che si uniscono in un'unica colonna per gli schermi 
 * piccoli. 
 */

const Footer = () => {

    const [showModal, setShowModal] = useState(false)
    const [manifesto, setManifesto] = useState(null)

    function openModal(index){

        if(index !== null ) setManifesto(ManifestoData.list[index])

        setShowModal(true)
    }

    function closeModal(){
        setManifesto(null)
        setShowModal(false)
    }

    return(
        <div className={`bg-gradient-to-b  from-secondary-600 to-gray-600 dark:from-white dark:to-gray-400
        dark:text-secondary-600 text-secondary-100 duration-500`}>

            <div className="relative flex flex-col sm:flex-row pt-2 pb-7 justify-between items-center">

                <div className="basis-1/3 flex flex-col justify-center items-center w-full">

                    <Logo linkClass={"dark:opacity-100 sm:opacity-60 hover:opacity-100 duration-300"}
                    imgClass={"hover:scale-110 duration-300"} />

                    <FlashContact />

                    {/* <div className="flex flex-col md:flex-row pt-5 text-center">
                        <span className="font-bold pr-0 md:pr-5">
                            LUN-VEN
                        </span>
                        9:00/12:30 - 14:30/17:30
                    </div> */}

                    <Socials />

                </div>

                <div className="basis-1/3 flex flex-col items-center justify-center text-lg py-5 w-full">
                    {/* <NewsLetterForm /> */}
                    {/* <Link to="/" className="flex justify-center items-center dark:opacity-100 sm:opacity-80 hover:opacity-100 duration-300" >
                        <img src={'/images/Techfriuli-logo.png'} alt="Techfriuli" className="sm:max-w-sm max-w-xs"/>
                    </Link> */}
                    <p className="flex font-bold text-2xl duration-200" >Techfriuli SRL</p>
                    <p className=" hover:scale-105 duration-200">P.Iva 02602970309</p>
                    <a className="text-primary-400 dark:text-primary-700 hover:text-primary-500  hover:scale-105 duration-200 text-base"
                    href={`${process.env.GATSBY_GOOGLE_SITE_MAPS}`}
                    target="blank_">
                        <p>Via Feletto 58, Udine &#8599;</p>
                    </a>
                    <p className=" hover:scale-105 duration-200">33100 Udine, UD</p>
                    <div className="flex justify-between items-center text-center pt-5 flex-row hover:scale-105 duration-200">
                        <span/>
                        <a href={`${process.env.GATSBY_IUBENDA_PRIVACY_POLICY_URL}`}
                        className="iubenda-black no-brand iubenda-noiframe iubenda-embed iubenda-noiframe "
                        title="Privacy Policy ">Privacy Policy</a>
                        <span className="px-3">|</span>
                        <a href={`${process.env.GATSBY_IUBENDA_COOKIE_POLICY_URL}`} 
                        className="iubenda-black no-brand iubenda-noiframe iubenda-embed iubenda-noiframe " 
                        title="Cookie Policy ">Cookie Policy</a>
                        <span />
                    </div>
                </div>
    
                <div className="basis-1/3 flex flex-col justify-center items-center py-5">
                    <p className="text-secondary-100 dark:text-secondary-600 font-bold text-2xl uppercase">Progetti EU e contributi </p>
                    <div className="text-center text-sm lg:px-14 px-5 md:px-1">
                        {ManifestoData.list.map((bando, index)=>{
                            return(
                                <div
                                key={index} role="button" className="cursor-pointer"
                                tabIndex={index}
                                onClick={()=>{
                                    openModal(index)
                                }}
                                onKeyDown={()=>{
                                    openModal(index)
                                }}>
                                    <p className=" dark:opacity-100 sm:opacity-80 hover:opacity-100 duration-300 py-2 hover:scale-105
                                    text-primary-400 dark:text-primary-700 hover:text-primary-500">
                                        {bando.title}
                                    </p>
                                </div>
                            )
                        })}
                        <div
                        role="button" className="cursor-pointer"
                        tabIndex={0}
                        onClick={()=>{
                            openModal(null)
                        }}
                        onKeyDown={()=>{
                            openModal(null)
                        }}>
                            <p className=" dark:opacity-100 sm:opacity-80 hover:opacity-100 duration-300 py-2 hover:scale-105
                            text-primary-400 dark:text-primary-700 hover:text-primary-500 uppercase">
                                Rendicontazione contributi pubblici 2020-2021
                            </p>
                        </div>
                    </div>
                </div>

            </div>

            <div className="flex flex-col lg:flex-row items-center justify-center 
            pb-3 pt-3 bg-gradient-to-b from-gray-700 to-secondary-600 dark:from-gray-400 
            dark:to-white text-secondary-100 dark:text-secondary-600">
                <span>
                    © Copyright {new Date().getFullYear()} TECHFRIULI S.R.L.
                </span>
                <span className="hidden lg:block px-3">
                    -
                </span>
                <span>
                    All rights are reserved
                </span>
            </div>
            {
            showModal &&
            <Modal handleClose={()=>closeModal()} >
            {
                manifesto !== null
                ? 
                <ManifestoPage manifesto={manifesto} />
                :
                <ReportingPage data={ReportingData}/>
            }
            </Modal>
            }
        </div>
    )
}

export default Footer