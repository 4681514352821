/**
 * @title Manifesto UE Data
 * @exports Object
 * @description File che ritorna un oggetto JS con i dati dei contributi UE ricevuti
 * per dal Progetto finanziato attraverso il Programma Operativo Regionale (POR) FESR 2014–2020.
 */

const Data = 
{
    list:
    [
        {
            title:"STRUMENTI INTEGRATI PER LA SICUREZZA DELLE INFORMAZIONI",
            img:"/images/manifesto/security.jpg",
            short_description:"Progetto finanziato attraverso il Programma Operativo Regionale (POR) FESR 2014 – 2020 “Investimenti a favore della crescita e dell’occupazione”, da Unione Europea – Fondo Europeo di Sviluppo Regionale, Repubblica Italiana e Regione Autonoma Friuli Venezia Giulia",
            description:`Vista la mole dei dati che Techfriuli archivia per conto della propria clientela, l'azienda ha deciso di dotarsi di strumenti che siano in grado di garantire il monitoraggio delle performances di tutti i suoi sitemi di archiviazione, la corretta esecuzione dei salvataggi periodici, la prevenzione delle intrusioni, l'affidabilità del sistema anche in caso di guasti o sbalzi/interruzioni di corrente.
            Il progetto mira ad accrescere l'affidabilità di Techfriuli come gestore di informazioni sui propri server garantendo in tal modo continuità di lavoro, riduzione dei rischi e consentendo ai clienti di ottenere agilità e stabilità.`,
            contribution:"12.451,25",
            goal:[
                "Garantire l'operatività dei sistemi aziendali 24/24 e 365/365 senza creare alcuna discontinuità per i clienti;",
                "Assicurare l'anonimato dei clienti e la riservatezza delle informazioni scambiate nel rispetto dei diritti altrui;",
                "Proteggere l'immagine aziendale e gli interessi degli stakeholder;",
                "Essere in grado di trasmettere affidabilità ala clientela;",
                "Migliorare le condizioni di lavoro interne all'organizzazione stessa;",
                "Delineare le principali misure necessarie a bloccare i tentativi di intrusione da parte di soggetti, siano essi esterni o interni, non autorizzati nei propri sistemi;",
                "Analizzare i requisiti per un efficiente Sistema di Gestione per la Business Continuity (Continuità Operativa);"
            ],
            result:[
                "Mantenere la sicurezza delle informazioni trattate, garantendo un buon livello di gestione aziendale e conformità, proteggendo l'immagine e la reputazione e creando un clima di fiducia per fornitori, portatori di interesse e clienti;",
                "Ottenere gli obiettivi di sicurezza in termini e costi ben definiti;",
                "Migliorare le performance, anche in caso di eventi o situazioni che limitano l'operatività;",
            ],
        },

        // {
        //     title:"CREAZIONE DI UN NUOVO MODELLO PER UN'ORGANIZZAZIONE INNOVATIVA E FLESSIBILE",
        //     img:"/images/manifesto/innovation.jpg",
        //     short_description:"Progetto finanziato attraverso il Programma Operativo Regionale (POR) FESR 2014 – 2020 “Investimenti a favore della crescita e dell’occupazione”, da Unione Europea – Fondo Europeo di Sviluppo Regionale, Repubblica Italiana e Regione Autonoma Friuli Venezia Giulia",
        //     description:`Il progetto di cui alla presente richiesta di contributo riguarda l’applicazione di nuovi metodi organizzativi nelle pratiche commerciali, nell’organizzazione del lavoro e nelle relazioni esterne dell’impresa, per permettere all’azienda di potersi adattare anche al “new normal”, dipendente dalla situazione generata dal post-COVID19.
        //     Si rende infatti necessaria una modifica dell’organizzazione aziendale per impostare una gestione ad alta redditività, cercando di eliminare i colli di bottiglia e gli altri orpelli che molto spesso sono stati fino ad oggi accettati come un male necessario (“si è fatto sempre così!”).`,
        //     contribution:"31.389,00",
        //     goal:[
        //         "Agire direttamente sulla cultura aziendale e combinare la creazione di una nuova piattaforma valoriale adeguata all’impresa moderna con un metodo scientifico e rigoroso per ridefinire i processi e le regole del contesto;",
        //         "Superare i limiti menzionati offrendo alle persone ciò che cercano nella propria esperienza lavorativa e, al contempo, garantendo reattività ed efficienza maggiori all’interno delle organizzazioni.",
        //     ],
        //     result:[
        //         "Miglioramento dei risultati di business;",
        //         "Benessere delle persone della propria organizzazione;",
        //         "Evidenziare l'attenzione che l'azienda pone ai propri clienti.",
        //     ],
        //     extra: "Si prevede pertanto di implementare un nuovo modello divisionale in chiave \"agile\", incentrato su una più ampia delega e responsabilizzazione dei gruppi di lavoro."
        // },
    ],
}

export default Data