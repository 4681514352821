import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

function scriptIubenda(window,document) {
  var loader = () => {
    var s = document.createElement("script"),
    tag = document.getElementsByTagName("script")[0]
    s.src= process.env.IUBENDA_SCRIPT_URL
    tag.parentNode.insertBefore(s,tag)
  }
  if(window.addEventListener){
    window.addEventListener("load", loader, false)
  }else if(window.attachEvent){
    window.attachEvent("onload", loader)
  }else{
    window.onload = loader
  }
}

// function scriptGoogleAnalitycs(window){

//   window.dataLayer = window.dataLayer || []

//   function gtag(){
//     window.dataLayer.push(arguments)
//   }
//   gtag('js', new Date());
//   gtag('config', 'G-EX6KP6XL6D');
// }

// Esecuzione script per integrazione Iubenda, policy
export const onClientEntry = () => {
  // console.log("ReactDOM.render has executed")
  scriptIubenda(window, document)
  // scriptGoogleAnalitycs(window)
}

export const wrapRootElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_GOOGLE_RECAPTCHA_CLIENT_API_KEY}>
      {element}
    </GoogleReCaptchaProvider>
  )
}