import React, { useEffect, useState } from "react"

/**
 * @title Reporti Page Layout Component
 * @param {Object} data
 * @returns {React.Component} Div Html element
 * @description Componente con il layout della pagina con i dettagli del report di rendicontazione. L'animazione in entrata è gestita
 * dall'Hook React useEffect() e dalla classi TailwindCSS. Riceve in input l'oggetto con i dati della rendicontazione.
 */

const Page = ({data}) => {
  
    const [animationIn, setAnimationIn] = useState("opacity-0")
    const title_style = "text-xl sm:text-2xl md:text-4xl xl:text-5xl my-10 text-primary-500 font-bold uppercase text-center"
    const subTitle_style = "text-lg sm:text-xl md:text-2xl xl:text-3xl mt-3 mb-10 text-primary-500 text-center"
    useEffect(()=>{
        setAnimationIn(" opacity-100 duration-1000")
    },[])

    return(

        Boolean(!data)
        
        ?
        
        <div className="text-secondary-600 max-w-full text-center md:text-justify">
            <p>Nessun dato trovato</p>
        </div>

        :
        
        <div className={"modal-container text-secondary-600 max-w-full text-center md:text-justify " + animationIn}>
            <p className={title_style}> {data.title}</p>
            <p className={subTitle_style}> {data.subTitle}</p>
            
            {/* <img src={data.img} alt="POR_FESR" className="py-5 max-w-full md:max-w-lg lg:max-w-xl xl:max-w-2xl"/> */}


            <div className="grid grid-cols-4 gap-4 text-center text-xs md:text-base">

                <div className="col-span-4 font-bold text-lg bg-blue-100">{data.table.header}</div>

                {
                    data.table.columns?.map( (col,index) =>{
                        return(
                            <div key={index} className="font-bold" > {col.title} </div>
                        )
                    })
                }
                {
                    data.table?.content.map( col =>{
                        return(
                            Object.entries(col).map( (subCol, ind) => {
                                return(
                                    <div key={ind} className="">
                                        {subCol[1]}
                                    </div>
                                )
                            })
                        )
                    })
                }
                <div className="md:col-span-2 pt-5"/>
                <div className="col-span-2 md:col-span-1 font-bold uppercase pt-5">
                    Totale Contributi
                </div>
                <div className="pt-5">
                    {data.table.totalAmount}
                </div>

            </div>

            <div className="text-left font-bold py-14">{data.docDate}</div>
            
        </div>
    )
}

export default Page