import React from "react"
import { ScrollSpy } from "./scroll-spy"

/**
 * @title WithLateralNavBar
 * @param {Object} props 
 * @returns {React.Component} Div Html
 * @description Componente che ritorna il menù di navigazione laterale della pagina, le cui voci vengono alimentate dal
 * parent, dove è presente una query che va a cercare tutte le "section" della pagina aventi un titolo e un id.
 * Inoltre, tramite il componente ScrollSpy importato crea Interseciton Observer per ogni sezione, per poter gestire
 * e aggiornare le voci del menù quando i componenti entrano nello schermo.
 */

const WithLateralNavBar = ({ children, selector, options }) => {

    const active_element = "!text-primary-500"

    const NavMenu = ({ options }) => {

        // control the click event
        const scrollTo = (event,target) => {
            event.preventDefault()
            document.getElementById(target).scrollIntoView({behavior: "smooth"})
        }
    
        return (
            <nav
            // data-sal="zoom-in" data-sal-duration="900"
            className="hidden md:flex flex-col h-full justify-center fixed z-20 top-0 left-10">
                {options.map((option, index) => {
                    return(
                        <button
                        key={index}
                        className="py-2 hidden xl:block"
                        data-scrollspy-id={option.hash}
                        onClick={(event) => {scrollTo(event,option.hash)}}>
                            {option.title}
                        </button>
                    )
                })}
            </nav>
        )
    }

    const onScrollUpdate = (entry, isInVewPort) => {

        const { target, boundingClientRect } = entry        
        const menuItem = document.querySelector(`[data-scrollspy-id="${target.id}"]`)
        // console.log(isInVewPort)
        // console.log(boundingClientRect.y)
        if(!menuItem) return
        
        if (boundingClientRect.y <= 300 && isInVewPort) {
            menuItem.classList.add(active_element)
            return
        }
        
        if (menuItem.classList.contains(active_element))
            menuItem.classList.remove(active_element)
    }

    return (
        <>
            <NavMenu options={options} />
            <ScrollSpy handleScroll={onScrollUpdate} selector={selector}/>
            {children}
        </>
    )
}

export default WithLateralNavBar