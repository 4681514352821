import React from 'react'
import { useTimeout } from './useTimeout'

/**
 * @title Toast React Component
 * @param {Boolean} error
 * @param {Function} close
 * @param {Object} children  
 * @returns {React.Component} Toast React Component
 * @description Componente che renderizza il Toast che apparirà in alto a destra dello schermo.
 * Il parametro "error", booleano, definisce il layout del messaggio di errore o informativo, "close" 
 * la funzione da eseguire per chiudere il Toast quando si preme il pulsante di chiusura X o trascorso il tempo
 * del timeout, "children" invece è semplicemente il testo o il contenuto all'interno del Toast.
 */
export const Toast = ({error,close,children}) => {

  let error_var = Boolean(error)

  useTimeout(close, 5000)

  return (
    <div className={"relative flex cursor-pointer mt-4 shadow-xl rounded-xl max-w-md  justify-center items-center "
        + (error_var ? "bg-red-300 border-red-400" : "bg-emerald-300 border-2 border-emerald-400")}>
      <div className="p-5">{children}</div>
      <div>
        <button onClick={close} className="border-none bg-transparent text-base mr-2 cursor-pointer">
          X
        </button>
      </div>
    </div>
  );
};
