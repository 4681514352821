import React , { useLayoutEffect, useState } from "react"
import PropTypes from "prop-types"

import Header from "../header"
import Footer from "../footer"
import TopButton from "../ui/button/top"
// import DarkButtonMode from "../ui/button/dark-mode"
// import WhatsAppButton from "../ui/button/whatsapp"
import WithLateralNavBar from "../ui/navbar/lateral-nav-bar"
import { SeparatorWave } from "../ui/divider/separator-wave"
import { ToastProvider } from "../toast/toast-provider"
import costanti from "../../properties/costanti"
import SettingsButton from "../ui/button/settings"

import "./global.css"

/**
 * @title Layout Page Component
 * @param {*} children Pagine del sito
 * @return {React.Component} Main section del sito web.
 * @description Componente html principale. E' figlio del Toast Provider ed è composto da un container che si autoadatta alla dimensione dello schermo
 * tramite classi tailwind. All'interno la pagina presenta un header, il contenuto della pagina e un footer. Ad arricchire il layout ci sono alcuni
 * componenti funzionali per l'utente. L'hook useLayoutEffect viene utilizzato per ricercare le section del sito da passare poi al componente 
 * lateral-nav-bar.
 */

const Layout = ({ children }) => {
  
  const [options, setOptions] = useState([])

  useLayoutEffect(() => {

      let navMenuSections
      let optionsFromSections

      setTimeout(() => {

        navMenuSections = document.querySelectorAll("section")
        optionsFromSections = Array.from(navMenuSections).map((section) => {
            return {
              hash: section.id,
              title: section.title.replaceAll("-"," ")
            }
        })
        setOptions(optionsFromSections)

      },1300)

  }, [children])

  return(
    <ToastProvider>
        <main className={"relative overflow-hidden text-justify bg-gray-50 dark:bg-gray-700 duration-300 "}>
          {/* <div className="w-screen h-screen fixed opacity-5 top-0 left-0 bg-cover animate-wobble-hor-bottom" ></div> */}
          <div className={costanti.theme.layout.width + " relative shadow-2xl " + costanti.theme.color.background.both}>

              <Header />
              
              <WithLateralNavBar selector="section" options={options}>
                {children}
              </WithLateralNavBar>
              {/* <LogoIconButton /> */}
              
              <SettingsButton/>
              
              <TopButton showBelow={100} />
              
              {/* <DarkButtonMode float /> */}
              {/* <WhatsAppButton /> */}
              
              <SeparatorWave />
              
              <Footer />

          </div>
        </main>
    </ToastProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout