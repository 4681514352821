import React from "react"
import { StaticQuery, graphql} from "gatsby"

import { Helmet } from "react-helmet"
import Navbar from "./ui/navbar/top-drawer"

/**
 * @Title Header
 * @param No No params
 * @returns {React.Component} 
 * @description Componente che tramite react-Helmet imposta titolo della pagina e precarica il google font del sito.
 * Oltre a questo nel tag HTML header richiama il componente NavBar.
 */

const Header = () => {
  return(
    <StaticQuery
    query={graphql`
      query SiteQuery {
          site {
              siteMetadata {
                  title
                  menuLinks {
                      name
                      link
                  }
              }
          }
      }`}
    render={data => (
      <>
        <Helmet>
          <title>{data.site.siteMetadata.title}</title>
          <link rel="stylesheet" href={`${process.env.GATSBY_GOOGLE_SITE_FONT}`} />
        </Helmet>
        <header>
          <Navbar pages={data.site.siteMetadata.menuLinks} />
        </header>    
      </>
    )}/>
  )
}

export default Header