import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowUp as faIcon} from '@fortawesome/free-solid-svg-icons'

/**
 * @title Top button Component
 * @param {Number} showBelow
 * @returns {React.Component} Button dentro un fragment
 * @description Componente che ritorna un pulsante con position fixed nella window, il cui scopo è quello di tornare ad inizio pagina quando premuto. 
 * La funzione riceve in input un unico parametro "showBelow" come props, di tipo number, che definisce dopo quanti pixel deve essere visibile il pulsante in questione.
 * La visibilità viene gestita tramite la presenza o meno delle rispettive classi CSS del framework TailwindCSS.
 * L'hook useEffect viene utilizzato per aggiungere un listener allo scroll event, garantendo la presenza di "window".
 * 
 * L'event listener aggiunto gestisce anche il layout della Top Navigation Bar, andrebbe spostato in futuro.
 */

const TopButton = ({showBelow}) => {
    
    const [show, setShow] = useState(false)

    const handleScroll = () => {
        // let navBar = document.getElementById("nav-bar")
        // window.scrollY === 0 ? navBar.classList.add("md:opacity-50") : navBar.classList.remove("md:opacity-50")
        // window.scrollY > 0 ? navBar.classList.add("opacity-50") : navBar.classList.remove("opacity-50")
        if (window.pageYOffset > showBelow){
            if (!show) setShow(true)
        }else{
            if (show) setShow(false)
        }
    }

    function handleClick() {
        window[`scrollTo`]({ top: 0, behavior: `smooth` })
    }
    
    useEffect(() => {
        if (showBelow) {
            window.addEventListener(`scroll`, handleScroll)
            //unmount
            return () => window.removeEventListener(`scroll`, handleScroll)
        }
    })
    
    return (
        <>
        {show &&
            <div className='z-20 fixed bottom-24 right-5'>
                <button onClick={()=>handleClick()}  aria-label="to top"
                className='bg-primary-500 text-white py-2 px-[0.90rem] rounded-lg 
                text-2xl opacity-50 hover:opacity-100 transition duration-500 
                hover:shadow-2xl border border-secondary-500 animate-fade-in-down'>
                    <FontAwesomeIcon icon={faIcon} />
                </button>
            </div>  
        }
        </>
    )
}
export default TopButton