import React from "react"
import { StaticQuery, graphql} from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import costanti from "../../../properties/costanti"
import Logo from "../logo"
// import DarkButtonMode from "../button/dark-mode"
import Svg from "../svg"
import Socials from "../../common/socials"
import FlashContact from "../../common/flash-contact"

/**
 * @title FloatMenu Component
 * @param {Function} handleCloseMenu
 * @param {Boolean} showLogo
 * @param {Boolean} showLink
 * @returns {React.Component} Div Html Element
 * @description Componente che contiene il menù Float che si apre
 * dopo aver premuto sul pulsante "settings", altro componente. La lista delle pagine
 * viene recuperata tramite query GraphQL nei metadati del progetto.
 */
const FloatMenu = ({handleCloseMenu, showLogo, showLink}) => {

  const span_style = "basis-1/2 flex flex-col px-3 py-3 items-center"
  const link_style = `relative hover:text-primary-500 dark:hover:text-primary-500
  after:duration-700 after:content-[''] after:absolute after:w-full after:h-0.5
  after:top-5 after:bg-primary-500 after:left-0 after:scale-0
  after:hover:scale-100 after:origin-bottom-left`

  return(
    <div className="relative w-full flex flex-col lg:flex-row justify-center items-center
    text-secondary-600 dark:text-white duration-300 max-h-fit">

      {showLink &&

        <div className="w-full flex flex-col justify-center items-center lg:items-end basis-1/2 p-2 lg:p-10">

          {showLogo && <Logo classes={"self-center"} handleOnClick={handleCloseMenu} />}

          <StaticQuery
          query={graphql`
            query getMenuList {
              site {
                siteMetadata {
                  menuLinks {
                    name
                    link
          }}}}`}

          render={data => (
            data.site?.siteMetadata?.menuLinks.map(
              (link, index) => {
                return(
                  <AniLink
                  paintDrip
                  hex={costanti.theme.color.hex}
                  direction="left" 
                  duration={1}
                  to={link.link}
                  onClick={()=>{
                    handleCloseMenu && handleCloseMenu()
                  }}
                  className={link_style + `lg:text-right landscape:text-left cursor-pointer font-sans text-4xl
                  pt-10 after:!top-20 after:h-1 text-secondary-500 dark:text-secondary-100 uppercase`}
                  activeClassName="!text-primary-500 dark:text-secondary-500 hover:text-secondary-100 dark:hover:text-secondary-500"
                  key={index}>
                    {link.name}
                </AniLink>
              )}
            )
          )} />

          <Socials classes={"pt-4 lg:pt-10"}/>

        </div>
      }
      
      <div className={"flex flex-col justify-center items-center text-center " + (showLink ? "p-3 lg:p-10 basis-1/2" : "w-full")}>
        <p className="pt-14 lg:pt-10 text-primary-500 text-2xl md:text-3xl">
          Come possiamo aiutarti?
        </p>

        <FlashContact />

        {/* <div className="flex flex-row pt-5">
          <span className="font-bold pr-5">
            LUN-VEN
          </span>
          9:00/12:30 - 14:30/17:30
        </div> */}

        <div className="hidden md:flex md:flex-row">
          <span className={span_style}>
            
            <p className="font-bold py-2 ">Mac</p>
            
            <a target="blank" href="https://anydesk.com/it/downloads/thank-you?dv=mac_dmg"
            className={link_style}>
              AnyDesk
            </a>
          </span>
          <span className={span_style}>

            <p className="font-bold py-2 ">Windows</p>
            
            <a target="blank" href="https://download.teamviewer.com/download/version_12x/TeamViewerQS.exe"
            className={link_style}>
              TeamViewerQS
            </a>

            <a target="blank" href="https://anydesk.com/it/downloads/thank-you?dv=win_exe"
            className={link_style}>
              AnyDesk
            </a>
          </span>
        </div>

        {/* <span className="flex flex-row justify-center items-center pt-5 md:pt-10 ">
          <p className="font-semibold pr-7">Modalità notte</p>
          <DarkButtonMode />
        </span> */}

        <Svg.BugFix className="hidden md:block w-2/3 hover:-hue-rotate-30 duration-700  " title="assistenza" />

      </div>
    </div>
  )
}

export default FloatMenu