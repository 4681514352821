import React, { useState, useMemo, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { ToastContext } from './toast-context'
import { Toast } from './toast'

// Create a random ID
function generateUEID() {
  let first = (Math.random() * 46656) | 0
  let second = (Math.random() * 46656) | 0
  first = ('000' + first.toString(36)).slice(-3)
  second = ('000' + second.toString(36)).slice(-3)

  return first + second
}

/**
 * @title Toast Provider
 * @param {Object} children 
 * @returns Provider per il Context importato.
 * @description Definisce il Provider del contesto importato e, tramite un portale (createPortal),
 * renderizza nel nodo del DOM indicato (secondo parametro della funzione) l'elemento descritto come 
 * primo argomento della funzione.
 */

export const ToastProvider = ({children}) => {
  
  const [toasts, setToasts] = useState([])
  const [portalContainer, setPortalContainer] = useState(null)

  
  const open = (content, error) =>
    setToasts(currentToasts => [
      ...currentToasts,
      { id: generateUEID(), content, error: error },
  ])

  const close = (id) =>
    setToasts(currentToasts =>
      currentToasts.filter((toast) => toast.id !== id)
  )
  
  const contextValue = useMemo(() => ({ open }), [])

  useEffect(()=>{
    setPortalContainer(
      // <>
      createPortal(
        <div className="fixed top-10 right-5 md:right-10 z-50">
          {toasts.map((toast) => (
            <Toast
            key={toast.id}
            close={() => close(toast.id)}
            error={toast.error}>
              {toast.content}
            </Toast>
          ))}
        </div>,
        document.getElementById("___gatsby")
        )
        // </>
    )},[toasts]
  )

  return (
    <ToastContext.Provider value={contextValue}>
      {children}
      {portalContainer}
    </ToastContext.Provider>
  )
}