import React from "react"
import costanti from "../../../properties/costanti"

/**
 * @title SeparatorWave Components
 * @param {String} marginTop
 * @param {String} background
 * @param {String} color
 * @returns {React.Component}
 * @description Componente che ritorna un divider a con il lato superiore ondulato. Gli input sono classi CSS (Tailwind).
 */

export const SeparatorWave = ({marginTop, background, color }) =>{

    const d = "M0.00,92.27 C216.83,192.92 304.30,8.39 500.00,109.03 L500.00,0.00 L0.00,0.00 Z"
    const style_div = "h-56 overflow-hidden " + (Boolean(marginTop) ? " mt-4" : "") + " " + (Boolean(background) ? background : costanti.theme.color.background.both_inverse)
    const fill_color = Boolean(color) ? color : costanti.theme.color.background.fill

    return(
        <div className={style_div} >
            <svg viewBox="0 0 500 150" preserveAspectRatio="none" className="h-full w-full ">
                <path d={d} style={{stroke: "none"}} className={fill_color} />
            </svg>
        </div>
    )
}