import React, { useEffect, useState } from "react"

import AniLink from "gatsby-plugin-transition-link/AniLink"
import Logo from "../logo"
import FloatMenu from "./float-menu"
import costanti from "../../../properties/costanti"

/**
 * @title Navbar Component
 * @param {*} props
 * @returns {React.Component} Div Html Element
 * @description Componente che ritorna la barra superiore con il menù di navigazione del sito.
 * Il menù è composto da una parte custom e dalla sezione con i contatti flash del Float Menù.
 * Le animazioni sono gestite dagli hook di React e le classi fornite da Tailwind.
 */
const Navbar = (props) => {
  
  const [navbarOpen, setNavbarOpen] = useState(false)
  const [burgerAnimation, setBurgerAnimation] = useState("")
  const [animationIn,setAnimationIn] = useState("scale-0 rounded-bl-full")
  const [opacity,setOpacity] =useState("opacity-0")

  //md:hidden
  const nav_bar_style=
  "fixed md:hidden flex items-center md:hover:opacity-100 shadow-xl md:shadow-2xl transition duration-500 "+
  "justify-between uppercase border-b-2 border-b-primary-500 self-center top-0 left-0 right-0 "+
  "h-[6vh] landscape:h-[10vh] md:h-["+costanti.theme.navbar.height.fromMSize+"] " +
  costanti.theme.layout.width + " " + costanti.theme.navbar.zIndex + " " +costanti.theme.color.background.both

  const link_style = `relative hover:text-primary-500 dark:hover:text-primary-500
  after:duration-700 after:content-[''] after:absolute after:w-full after:h-0.5
  after:top-5 after:bg-primary-500 after:left-0 after:scale-0
  after:hover:scale-100 after:origin-bottom-left`

  const closeMenu = () =>{
    setNavbarOpen(false)
  }

  useEffect(()=>{
    if(navbarOpen){
      setAnimationIn("scale-100 rounded-bl-none")
      setBurgerAnimation("-rotate-45 before:-rotate-90 after:rotate-90 before:-translate-x-2 before:translate-y-0 after:opacity-0 before:translate-x-0 before:translate-y-2")
    }else{
      setAnimationIn("scale-0 rounded-bl-full")
      setBurgerAnimation("after:opacity-100")
    }
  },[navbarOpen])

  useEffect(()=>{
    if(navbarOpen){
      setOpacity("opacity-100 duration-500 delay-500")
    }else{
      setOpacity("opacity-0")
    }
  },[animationIn,navbarOpen])

  const NavItems = () => {
    return (
      props.pages && props.pages.map((link, index) => {
        return(
          <div key={link+index} className="mt-2">
            <AniLink
            paintDrip
            hex={costanti.theme.color.hex}
            // duration={1}
            to={link.link}
            onClick={()=>{
              closeMenu()
            }}
            className={link_style + `lg:text-right landscape:text-left cursor-pointer font-sans text-4xl
            after:!top-11 after:h-1 text-secondary-500 dark:text-secondary-100 uppercase`}
            activeClassName="!text-primary-500 dark:text-secondary-500 hover:text-secondary-100 dark:hover:text-secondary-500">
              {link.name}
            </AniLink>
            <br/>
          </div>
        )
      })
    )
  }

  // La gestione del cambio di opacità è nel listener legato allo scrolling, nel componente TopButton, dove vado a cercare l'elemento per id
  return (
    <nav id="nav-bar" className={nav_bar_style}>

      <Logo />
      
      <div role="button" tabIndex={0}
      className="flex md:hidden cursor-pointer h-full px-10"
      onClick={() => setNavbarOpen(!navbarOpen)}
      onKeyDown={()=>setNavbarOpen(!navbarOpen)}>

        <div 
        className={"relative bg-secondary-600 dark:bg-secondary-100 before:bg-secondary-600 after:bg-secondary-600 "+
        "dark:after:bg-secondary-100 dark:before:bg-secondary-100 w-7 h-1 self-center cursor-pointer relative transition-all "+
        "before:content-[''] after:content-[''] before:w-7 before:h-1 after:w-7 after:h-1 before:absolute after:absolute "+
        "after:top-2 before:-top-2 duration-300 before:duration-300 after:duration-300 before:after:transition-all z-50 " + 
        burgerAnimation }/>

      </div>
      
      <div 
      className={"fixed w-full h-full flex flex-col justify-center top-0 " +
      "bg-white dark:bg-secondary-600 z-40 items-center " +
      "origin-top-right duration-500 " + animationIn }>

        <div className={`flex landascape:flex-row portrait:flex-col portrait:text-right
        landscape:w-11/12 justify-center items-center h-5/6 m-auto `  + opacity }>

          <span className="w-full">
            <Logo handleOnClick={closeMenu} />
            <br/>
            <NavItems />
          </span>
          
          <FloatMenu />

        </div>
      
      </div>

    </nav>
  )
}

export default Navbar